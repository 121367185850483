<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      Student Form Log
    </template>
    <template slot="body">
      <md-list>
        <md-list-item
          v-for="log in item.log"
          :key="log.date"
        >
          {{ log.msg }} <small>{{ dateTimeFormat(log.date) }}</small>
        </md-list-item>
      </md-list>
    </template>
    <template slot="footer">
      <md-button
        class="md-danger"
        @click="close"
      >
        Close
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
<style></style>
