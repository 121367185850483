/* eslint-disable no-restricted-properties */
export default {
  validate(value, { qty }) {
    let val = 0;

    if (window.isNaN(value)) {
      val = parseFloat(value.split(',').join(''));

      if (window.isNaN(val)) {
        return false;
      }
    } else {
      val = value;
    }
    return val > qty;
  },
  params: ['qty'],
};
