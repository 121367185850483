<template>
  <vue-modal
    ref="modal"
    size="lg"
    @close="$emit('close')"
  >
    <template slot="title">
      Student Form
    </template>
    <template slot="body">
      <ValidationObserver ref="validator">
        <form
          v-if="form.details"
          ref="form"
        >
          <!-- Student -->
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <h3 class="mt-0">
                Student
              </h3>

              <div
                v-if="!item"
                class="d-flex mb-4"
              >
                <md-button
                  class="md-primary w-50"
                  @click="newStudent = true"
                >
                  New Student
                </md-button>

                <md-button
                  class="md-info w-50"
                  @click="newStudent = false"
                >
                  Existent Student
                </md-button>
              </div>

              <md-switch
                v-model="form.details.payments_by_agent"
                :disabled="isApproved"
                class="text-black"
              >
                Payment information will be carried out by the agent
              </md-switch>

              <StudentsAutocomplete
                v-if="!newStudent"
                label="Search Student*"
                rules="required"
                @change="form.student = $event"
              />
              <form-text
                v-else
                v-model="form.email"
                icon="email"
                name="email"
                label="Email"
                rules="required|email"
                :disabled="item !== null"
                :max-chars="120"
              />
              <form-text
                v-if="item"
                v-model="form.details.first_name"
                icon="face"
                label="Given Name"
                :disabled="item.student !== null"
                asterisk
                :max-chars="120"
              />

              <form-text
                v-if="item"
                v-model="form.details.last_name"
                label="Surname"
                icon="record_voice_over"
                :disabled="item.student !== null"
                asterisk
                :max-chars="120"
              />

              <CountriesAutocomplete
                v-if="item"
                v-model="form.details.country_id"
                label="Country"
                :disabled="isApproved"
                asterisk
              />

              <form-radio
                v-if="item"
                v-model="form.details.gender"
                name="gender"
                :options="[
                  {value:'M', label:'Male'},
                  {value:'F', label:'Female'},
                ]"
                :disabled="isApproved"
                asterisk
              />

              <form-date
                v-if="item"
                v-model="form.details.date_of_birth"
                name="birth_date"
                label="Birth Date"
                :disabled="isApproved"
                asterisk
              />

              <form-text
                v-if="item"
                v-model="form.details.phone"
                icon="phone"
                name="phone"
                label="Phone"
                :disabled="isApproved"
                asterisk
                :max-chars="60"
              />

              <form-text
                v-if="item"
                v-model="form.details.emergency_contact_name"
                icon="person_outline"
                label="Emergency Contact Name"
                :disabled="isApproved"
                asterisk
                :max-chars="120"
              />
              <form-text
                v-if="item"
                v-model="form.details.emergency_contact"
                icon="contact_page"
                label="Emergency Contact"
                :disabled="isApproved"
                asterisk
                :max-chars="120"
              />

              <MktSourcesSelect
                v-model="form.details.mkt_source_id"
                :disabled="isApproved"
                asterisk
              />
            </div>

            <!-- Passport -->
            <div
              v-if="form.details.passports && form.details.passports.length"
              class="md-layout-item md-size-100"
            >
              <h5>Passport</h5>
              <form-text
                v-if="item"
                v-model="form.details.passports[0].number"
                icon="card_travel"
                name="passport"
                label="Passport"
                :disabled="isApproved"
                asterisk
                :max-chars="30"
              />

              <CountriesAutocomplete
                v-if="item"
                v-model="form.details.passports[0].country_id"
                label="Passport Country"
                :disabled="isApproved"
                asterisk
              />

              <form-date
                v-if="item"
                v-model="form.details.passports[0].issue_date"
                name="passport_issue"
                label="Passport Issue"
                :disabled="isApproved"
                asterisk
              />

              <form-date
                v-if="item"
                v-model="form.details.passports[0].expiration_date"
                name="passport_expiration"
                label="Passport Expiration"
                :disabled="isApproved"
                asterisk
              />

              <md-button
                class="md-info"
                :disabled="!form.passport_file"
                @click="openFileUrl(form.passport_file.file_id)"
              >
                Preview Passport File
                <md-icon>visibility</md-icon>
              </md-button>
            </div>

            <!-- Entry Visa -->
            <div
              v-if="item && form.entry_visa_file"
              class="md-layout-item md-size-100"
            >
              <h5>Entry Visa</h5>
              <div class="d-flex">
                <md-button
                  class="md-info"
                  @click="openFileUrl(form.entry_visa_file.file_id)"
                >
                  Preview Entry Visa Certificate
                  <md-icon>visibility</md-icon>
                </md-button>
              </div>
            </div>

            <!-- Flight -->
            <div
              v-if="item"
              class="md-layout-item md-size-100"
            >
              <h5>Flight</h5>
              <template
                v-if="form.details.flight_status === 'have_ticket' && form.details.flight"
              >
                <FormText
                  v-model="form.details.flight.flight_code"
                  label="Flight Code"
                  icon="money"
                  :disabled="isApproved"
                  asterisk
                  :max-chars="30"
                />
                <FormDate
                  v-model="form.details.flight.departure_date"
                  date-time
                  label="Departure Date"
                  :disabled="isApproved"
                  asterisk
                />
                <FormDate
                  v-model="form.details.flight.arrival_date"
                  date-time
                  label="Arrival Date"
                  :disabled="isApproved"
                  asterisk
                />
                <FormText
                  v-model="form.details.flight.airline"
                  icon="airplanemode_active"
                  label="Airline"
                  :disabled="isApproved"
                  asterisk
                  :max-chars="30"
                />
                <div
                  v-if="flightFileURL"
                  class="d-flex"
                >
                  <md-button
                    :href="flightFileURL"
                    target="_blank"
                    class="md-info"
                  >
                    Preview Flight File
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
              </template>

              <FormText
                v-else
                icon="airplanemode_active"
                :label="flight_status"
                asterisk
                disabled
              />
            </div>

            <!-- GNIB -->
            <div
              v-if="item"
              class="md-layout-item md-size-100"
            >
              <template
                v-if="!form.details.no_gnib && form.details.gnib"
              >
                <h5>IRP CARD</h5>
                <FormNumber
                  v-model="form.details.gnib.reg_number"
                  icon="tag"
                  label="Registration Number"
                  :disabled="isApproved"
                  asterisk
                />
                <FormDate
                  v-model="form.details.gnib.issue_date"
                  label="Issue Date"
                  :disabled="isApproved"
                  asterisk
                />
                <FormDate
                  v-model="form.details.gnib.expiration_date"
                  label="Expiration Date"
                  :disabled="isApproved"
                  asterisk
                />
                <FormSelect
                  v-model="form.details.gnib.stamp"
                  icon="approval"
                  label="Stamp"
                  :options="stampsOpts"
                  :disabled="isApproved"
                  asterisk
                />
              </template>

              <FormText
                v-else
                icon="approval"
                label="Student has no IRP Card yet"
                disabled
              />
            </div>

            <!-- Address -->
            <div
              v-if="form.details.addresses && form.details.addresses.length"
              class="md-layout-item md-size-100"
            >
              <h5>Address</h5>
              <FormSelect
                v-if="item"
                v-model="form.details.addresses[0].type"
                name="address_type1"
                icon="local_offer"
                label="Address Type"
                :options="address_types"
                :disabled="isApproved"
                asterisk
              />
              <form-text
                v-if="item"
                v-model="form.details.addresses[0].address"
                icon="my_location"
                name="address1"
                label="Address"
                :disabled="isApproved"
                asterisk
                :max-chars="220"
              />
              <form-text
                v-if="item"
                v-model="form.details.addresses[0].city"
                name="address_city1"
                icon="local_offer"
                label="City"
                :disabled="isApproved"
                asterisk
                :max-chars="120"
              />
              <form-text
                v-if="item"
                v-model="form.details.addresses[0].county"
                name="address_county1"
                icon="local_offer"
                label="Address County"
                :disabled="isApproved"
                :max-chars="120"
              />
              <CountriesAutocomplete
                v-if="item"
                v-model="form.details.addresses[0].country_id"
                label="Address Country"
                :disabled="isApproved"
                asterisk
              />
              <form-text
                v-if="item"
                v-model="form.details.addresses[0].zip_code"
                name="address_zip_code1"
                icon="local_offer"
                label="Address Zip Code"
                :disabled="isApproved"
                :max-chars="20"
              />
            </div>
          </div>

          <!-- Programme -->
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <h3>Programme</h3>

              <QuotationTemplateSelect
                v-model="form.quotation_id"
                :disabled="item && !!item.student_form_id"
              />
              <AgencySelect
                v-model="form.agency_id"
                :disabled="item && !!item.student_form_id"
                label="Agency (Emails will be re-directed to the Agency's Email)"
                @change="onAgencySelected"
              />
              <md-switch
                v-if="signRequestActive && !item"
                v-model="form.already_signed"
                :disabled="!!form.agency_id"
              >
                Student already signed
              </md-switch>
              <SignRequestTemplatesSelect
                v-if="signRequestActive && !form.already_signed && !item"
                v-model="form.details.template_id"
                :disabled="form.already_signed"
              />
              <div class="d-flex">
                <FormUploader
                  v-if="form.already_signed"
                  class="pb-2"
                  @input="onTermsUploaded"
                  @error="fireError"
                >
                  <md-button class="md-info">
                    <md-icon>file_present</md-icon>
                    Upload Signed Terms & Conditions <span class="asterisk"> *</span>
                  </md-button>

                  <br>
                  <small>File must be JPG/PNG/PDF max 5MB</small>
                  <br>
                  <small
                    v-if="form.terms_file && !form.terms_file.file_id"
                    class="text-success font-weight-bold"
                  >
                    File to Upload: {{ form.terms_file.name }}
                  </small> <br>
                </FormUploader>
                <div
                  v-if="termsFileURL"
                >
                  <md-button
                    :href="termsFileURL"
                    target="_blank"
                    class="md-info"
                  >
                    Preview Uploaded Terms & Conditions File
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
              </div>
              <div
                v-if="autoPlacementTestActive"
                class="d-flex"
              >
                <ExamsSelect
                  v-model="form.exam_id"
                  class="flex-grow-1"
                  :disabled="isApproved"
                />
                <FormDate
                  v-if="form.exam_id"
                  v-model="form.exam_expire_at"
                  label="Exam Finish Date"
                  rules="required"
                  :disabled="isApproved"
                />
              </div>
              <div class="d-flex">
                <AgentsSelect
                  v-model="form.agent_id"
                  :disabled="isApproved || (userIsAgent && !userIsAgentsManager)"
                  class="flex-grow-1"
                  :agent-groups="agentGroupsManagerIds"
                  @change="onAgentSelected"
                />
                <CommissionsSelect
                  v-if="canSeeCommision"
                  v-model="form.commission_id"
                  :disabled="isApproved"
                  :agent="selectedAgent"
                  class="ml-4"
                  no-percentage
                />
              </div>

              <PeriodsSelect
                v-model="form.class_schedule_id"
                :disabled="isApproved"
                rules="required"
                label="Period"
              />
              <CoursesSelect
                v-model="form.details.course"
                rules="required"
                :disabled="isApproved"
                @change="onChangeCourse"
              />
              <md-switch
                v-model="form.details.no_date"
                :disabled="isApproved"
                @change="onChangeNoDate"
              >
                Programme hasn't Start Date
              </md-switch>
              <form-date
                v-if="!form.details.no_date"
                v-model="form.details.start_date"
                name="start_date"
                label="Start Date"
                rules="required"
                :disabled="isApproved"
              />
              <form-number
                v-model="form.details.course_weeks"
                name="course_weeks"
                label="Course Weeks"
                rules="required"
                icon="notes"
                :disabled="isApproved"
              />
              <form-number
                v-model="form.details.holiday_weeks"
                name="holiday_weeks"
                label="Holiday Weeks"
                rules="required"
                icon="notes"
                :disabled="isApproved"
              />
              <VisaTypesSelect
                v-model="form.details.visa_type"
                :disabled="isApproved"
                rules="required"
              />
            </div>
          </div>

          <!-- Package -->
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <h3>Package</h3>
              <div
                v-if="!isApproved"
                class="md-layout mb-3"
              >
                <div class="md-layout-item md-size-50 ml-auto">
                  <PackageItemsSelect
                    :filter="optionalFilter"
                    label="Item to Add"
                    @change="itemSelected = $event"
                    @ready="onPackageItemsLoaded"
                  />
                </div>
                <div class="md-layout-item md-size-25">
                  <md-button
                    class="md-raised md-success float-right"
                    @click="addItem"
                  >
                    Add New Item
                  </md-button>
                </div>
              </div>

              <template v-if="form.details.package_items.length">
                <div
                  v-for="(pitem,index) in form.details.package_items"
                  :key="pitem.key"
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-35">
                    <FormText
                      v-if="pitem"
                      :value="pitem.name"
                      disabled
                      label="Item"
                      icon="description"
                    />
                  </div>
                  <div class="md-layout-item md-size-15">
                    <FormNumber
                      :value="pitem.units"
                      rules="required"
                      icon="tag"
                      label="Units"
                      :disabled="isApproved"

                      @input="itemUnitsChanged($event, index)"
                    />
                  </div>
                  <div class="md-layout-item md-size-20">
                    <FormNumber
                      currency
                      :value="pitem.price"
                      rules="required|minCurrencyValue:0"
                      icon="euro"
                      label="Unit Price"
                      :disabled="isApproved || pitem.fixed_price"
                      @input="itemPriceChanged($event, index)"
                    />
                  </div>
                  <div class="md-layout-item md-size-20">
                    <FormNumber
                      currency
                      :value="getSubtotal(pitem)"
                      icon="euro"
                      label="Subtotal"
                      disabled
                    />
                  </div>
                  <div class="md-layout-item md-size-10">
                    <md-button
                      v-if="!pitem.required && !isApproved"
                      class="md-button md-just-icon md-danger md-round"
                      @click="deleteItem(index)"
                    >
                      <md-icon>delete</md-icon>
                    </md-button>
                  </div>
                </div>
              </template>

              <div class="md-layout mt-5">
                <div class="md-layout-item md-size-30 ml-auto">
                  <FormNumber
                    currency
                    :value="package_total"
                    icon="euro"
                    label="Total"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Payments -->
          <div
            v-if="item"
            class="md-layout"
          >
            <div class="md-layout-item md-size-100">
              <h3>Payments</h3>
              <md-button
                v-if="form.details.payments_by_agent && !isApproved"
                class="md-primary w-100 md-sm"
                @click="newPayment"
              >
                <md-icon>add</md-icon>
                New Payment
              </md-button>

              <div class="md-layout">
                <div class="md-layout-item md-size-30">
                  Expected Date
                </div>
                <div class="md-layout-item md-size-30">
                  Amount
                </div>
                <div class="md-layout-item md-size-30">
                  Method
                </div>
              </div>
              <div
                v-for="(payment,index) in package_payments"
                :key="payment.key"
                class="md-layout"
              >
                <div class="md-layout-item md-size-30">
                  <form-date
                    v-model="payment.expected_date"
                    name="expected_date"
                    label="Select date"
                    rules="required"
                    :disabled="!form.details.payments_by_agent || isApproved"
                  />
                </div>
                <div class="md-layout-item md-size-30">
                  <FormNumber
                    v-model="payment.amount"
                    currency
                    name="amount"
                    icon="euro"
                    rules="required|minCurrencyValue:0"
                    :disabled="!form.details.payments_by_agent || isApproved"
                  />
                </div>
                <div class="md-layout-item md-size-30">
                  <PaymentMethodsSelect
                    v-model="payment.payment_method_id"
                    rules="required"
                    label="Select a Payment method"
                    :disabled="!form.details.payments_by_agent || isApproved"
                    :with-deleted="!form.details.payments_by_agent || isApproved"
                  />
                </div>
                <div class="md-layout-item md-size-10">
                  <md-button
                    v-if="form.details.payments_by_agent && !isApproved"
                    class="md-danger md-simple md-just-icon"
                    @click="deletePayment(index)"
                  >
                    <md-icon>close</md-icon>
                    <md-tooltip>Delete Payment</md-tooltip>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        v-if="item && isFullyAnswered && !item.checked_at"
        class="md-primary"
        @click="onCheck"
      >
        Check Form Data
      </md-button>
      <md-button
        v-if="item && isFullyAnswered"
        class="md-primary"
        @click="approve"
      >
        Approve Form
      </md-button>
      <md-button
        class="md-success"
        @click="validate()"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email } from 'vee-validate/dist/rules';
import minCurrencyValue from '@/core/validators/minCurrencyValue';
import {
  FormText,
  FormNumber,
  FormRadio,
  FormDate,
  FormSelect,
  FormUploader,
} from '@/components/Inputs';
import {
  CountriesAutocomplete,
  ExamsSelect,
  StudentsAutocomplete,
  MktSourcesSelect,
  SignRequestTemplatesSelect,
  AgentsSelect,
  CommissionsSelect,
  CoursesSelect,
  VisaTypesSelect,
  PackageItemsSelect,
  PaymentMethodsSelect,
  AgencySelect,
  QuotationTemplateSelect,
  PeriodsSelect,
} from '@/components/Inputs/selects';

import moment from 'moment';

extend('required', required);
extend('email', email);
extend('minCurrencyValue', minCurrencyValue);

export default {
  components: {
    FormText,
    FormNumber,
    FormDate,
    FormRadio,
    FormSelect,
    FormUploader,
    CountriesAutocomplete,
    ExamsSelect,
    StudentsAutocomplete,
    MktSourcesSelect,
    SignRequestTemplatesSelect,
    AgentsSelect,
    CommissionsSelect,
    CoursesSelect,
    VisaTypesSelect,
    PackageItemsSelect,
    PaymentMethodsSelect,
    AgencySelect,
    QuotationTemplateSelect,
    PeriodsSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    flightFileURL: null,
    termsFileURL: null,
    loading: false,
    form: {
      terms_file: null,
      already_signed: false,
      email: null,
      quotation_id: null,
      agent_id: null,
      agency_id: null,
      commission_id: null,
      class_schedule_id: 2,
      exam_id: null,
      exam_expire_at: null,
      details: {
        template_id: null,
        course: null,
        course_weeks: null,
        holiday_weeks: null,
        package_items: [],
        package_payments: [],
        start_date: null,
        visa_type: '1st Visa',
        payments_by_agent: true,
      },
    },
    students: [],
    newStudent: true,
    selectedAgent: null,
    address_types: [
      { id: 'Local', name: 'Irish Address' },
      { id: 'Home', name: 'Home' },
    ],
    stampsOpts: [
      { id: 'STAMP 1/1G', name: 'STAMP 1/1G' },
      { id: 'STAMP 2', name: 'STAMP 2' },
      { id: 'STAMP 4', name: 'STAMP 4' },
      { id: 'EUROPEAN CITIZENSHIP', name: 'EUROPEAN CITIZENSHIP' },
      { id: 'OTHER', name: 'OTHER' },
    ],
    opt_package_items: [],
    package_payments: [],
    itemSelected: null,
    package_total: 0,
  }),
  computed: {
    isApproved() {
      if (!this.item) return false;
      if (this.item.status === 'Approved') return true;
      return false;
    },
    isSent() {
      if (!this.item) return false;
      return this.item.email_sent;
    },
    isFullyAnswered() {
      if (!this.item) return false;
      if (this.item.status === 'Fully Answered') return true;
      return false;
    },
    flight_status() {
      if (this.form.details.flight_status === 'have_ticket') return 'Student has flight ticket';
      if (this.form.details.flight_status === 'living_in_ireland') return 'Student is already living in Ireland';
      return 'Student has not flight ticket yet';
    },
    totalPayments() {
      if (this.package_payments.length) {
        return this.form.details.package_payments.reduce((a, b) => {
          if (!b.status || (b.status && b.status !== 'Void')) {
            return a + (b.amount ? parseFloat(b.amount) : 0);
          }
          return a + 0;
        }, 0);
      }
      return 0;
    },
    canSeeCommision() {
      let canDoIt = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const a of this.user.roles) {
        // eslint-disable-next-line no-restricted-syntax
        for (const p of a.permissions) {
          if (p.permission_id === 1) canDoIt = true;
        }
      }

      return !!this.selectedAgent && (!this.isSent || canDoIt);
    },
  },
  watch: {
    package_payments: {
      deep: true,
      handler(val) {
        this.form.details.package_payments = val;
      },
    },
  },
  async mounted() {
    if (this.item) {
      this.form = JSON.parse(JSON.stringify(this.item));

      if (this.form.details.flight) {
        this.form.details.flight.departure_date = this.form.details.flight.departure_date
          ? moment(this.form.details.flight.departure_date).format('YYYY-MM-DD HH:mm') : null;
        this.form.details.flight.arrival_date = this.form.details.flight.arrival_date
          ? moment(this.form.details.flight.arrival_date).format('YYYY-MM-DD HH:mm') : null;
      }

      if (this.form.file) {
        this.flightFileURL = await this.getFileUrl(this.form.file.file_id);
      }
      if (this.form.terms_file) {
        this.termsFileURL = await this.getFileUrl(this.form.terms_file.file_id);
      }

      if (!this.form.details.package_payments) {
        this.package_payments = [];
      } else {
        this.package_payments = this.form.details.package_payments;
      }
    } else if (this.user.is_agent) {
      this.form.agent_id = this.user.user_id;
    }

    if (!this.signRequestActive) this.form.already_signed = true;

    if (this.isSent) this.packTotalCalculate();
  },
  methods: {
    optionalFilter(x) {
      return !this.form.details.package_items
        .map((y) => y.package_item_id)
        .includes(x.package_item_id);
    },
    onPackageItemsLoaded() {
      if (!this.item) {
        this.form.details.package_items = [...this.$store.getters['packageItems/required']];
      }
      this.packTotalCalculate();
    },
    close() {
      this.$refs.modal.close();
    },
    onAgentSelected(agent) {
      this.selectedAgent = agent;

      if (agent) {
        const commission_ids = agent.commissions.map((x) => x.agent_commission_id);

        if (!this.form.commission_id) {
          [this.form.commission_id] = commission_ids;
        }

        if (!commission_ids.includes(this.form.commission_id)) {
          this.form.commission_id = null;
        }
      } else {
        this.form.commission_id = null;
      }
    },
    onAgencySelected() {
      this.form.already_signed = true;
    },

    addItem() {
      if (this.itemSelected) {
        const itemToAdd = { ...this.itemSelected };

        itemToAdd.price = itemToAdd.default_price;
        itemToAdd.key = Math.random() * Date.now();

        this.form.details.package_items.push(itemToAdd);
        this.itemSelected = null;
      }
      this.packTotalCalculate();
    },
    deleteItem(index) {
      this.form.details.package_items.splice(index, 1);
      this.packTotalCalculate();
    },
    itemPriceChanged(value, index) {
      this.form.details.package_items[index].price = value;
      this.packTotalCalculate();
    },
    itemUnitsChanged(value, index) {
      this.form.details.package_items[index].units = value;
      this.packTotalCalculate();
    },
    packTotalCalculate() {
      if (!this.form.details.package_items.length) this.package_total = '0.00';

      this.package_total = this.form.details.package_items
        .reduce((a, b) => a + (parseFloat(b.price) * b.units), 0).toFixed(2);
    },
    onChangeCourse(course) {
      if (!this.item) {
        this.form.details.course_weeks = course.course_weeks;
        this.form.details.holiday_weeks = course.holiday_weeks;
      }
    },
    approve() {
      this.validate(() => {
        this.$emit('onApprove', { ...this.form });
      });
    },
    onTermsUploaded(file) {
      this.form.terms_file = file;
      this.termsFileURL = URL.createObjectURL(file);
    },
    onChangeNoDate(val) {
      if (val) {
        this.form.details.start_date = moment('2000/01/01');
      } else {
        this.form.details.start_date = null;
      }
    },

    onCheck() {
      this.$emit('onCheck', { ...this.form });
    },
    newPayment() {
      this.package_payments.push({
        key: Math.random() * Date.now(),
        expected_date: moment().add(1, 'day'),
        amount: (this.package_total - this.totalPayments),
        payment_method_id: 1,
      });
      this.$forceUpdate();
    },
    deletePayment(index) {
      this.package_payments.splice(index, 1);
      this.$forceUpdate();
    },
    async validate(callback = null) {
      this.loading = true;
      await this.$refs.validator.validate().then(async (res) => {
        if (res) {
          if (this.form.student_form_id) {
            this.request(`student_forms/${this.form.student_form_id}`, 'put', this.form, () => {
              this.fireSuccess('Form updated succesfully.');
              if (callback) {
                callback();
              }
              this.$emit('onSave');
              this.close();
            }, () => {
              this.loading = false;
            });
          } else {
            if (this.form.already_signed && !this.form.terms_file) {
              this.fireError('If Student already signed, please upload Terms & Conditions to continue');
              this.loading = false;
              return;
            }

            // SEND FORM DATA TO UPLOAD FILE
            const finalData = this.paramsSanitization(this.form);

            const formData = new FormData();
            formData.append('details', JSON.stringify(finalData.details));
            formData.append('terms_file', finalData.terms_file);
            formData.append('agent_id', finalData.agent_id);
            formData.append('quotation_id', finalData.quotation_id);
            formData.append('agency_id', finalData.agency_id);
            formData.append('commission_id', finalData.commission_id);
            formData.append('class_schedule_id', finalData.class_schedule_id);
            formData.append('exam_id', finalData.exam_id);
            formData.append('exam_expire_at', finalData.exam_expire_at);
            formData.append('already_signed', finalData.already_signed);
            formData.append('email', finalData.email);
            formData.append('student', JSON.stringify(finalData.student));

            this.request('student_forms', 'post', formData, () => {
              this.fireSuccess('Form created succesfully.');
              if (callback) {
                callback();
              }
              this.$emit('onSave');
              this.close();
            }, () => {
              this.loading = false;
            });
          }
        } else {
          this.fireError('Complete all the required fields');
          this.loading = false;
        }
      });
    },
    getSubtotal(item) {
      return parseFloat(item.price) * item.units;
    },
  },
};
</script>
<style scoped>
h3 {
  border-bottom: 1px solid #4caf50;
  margin-bottom: 20px;
  margin-top: 30px;
}
::v-deep .md-switch.text-black label {
  color: black;
}
</style>
