<template>
  <div>
    <!-- Spinner -->

    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mx-0">
          <div
            class="md-layout-item"
            :class="!(userIsAgentsManager || !userIsAgent) ? 'md-size-33' : 'md-size-25'"
          >
            <FormSelect
              v-model="filters.signed"
              icon="assignment_turned_in"
              label="Signed"
              :options="[
                { id: 'false', name: 'NO' },
                { id: 'true', name: 'YES' },
              ]"
            />
          </div>
          <div
            class="md-layout-item"
            :class="!(userIsAgentsManager || !userIsAgent) ? 'md-size-33' : 'md-size-25'"
          >
            <FormStatusesSelect
              v-model="filters.status"
            />
          </div>
          <div
            v-if="userIsAgentsManager || !userIsAgent"
            class="md-layout-item md-size-25"
          >
            <AgentsSelect
              v-model="filters.agent_id"
              :agent-groups="agentGroupsManagerIds"
            />
          </div>
          <div
            class="md-layout-item"
            :class="!(userIsAgentsManager || !userIsAgent) ? 'md-size-33' : 'md-size-25'"
          >
            <form-date
              v-model="filters.email_sent_at"
              name="sent_at"
              icon="clock"
              label="Sent Date"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm md-block"
              @click="initTable"
            >
              Filter
            </md-button>
          </div>
        </div>

        <!-- BULK ACTION BUTTONS -->
        <md-button
          class="md-just-icon md-round md-primary pull-right"
          @click="createNewStudent"
        >
          <md-icon>add</md-icon>
          <md-tooltip>New Student Form</md-tooltip>
        </md-button>
        <md-button
          class="md-just-icon md-round md-info pull-right"
          @click="sendEmailBulk"
        >
          <md-icon>mail</md-icon>
          <md-tooltip>Send email to all Waiting Recipients</md-tooltip>
        </md-button>

        <div
          class="d-inline-flex pull-right"
        >
          <md-switch
            v-model="filters.show_deleted"
            @change="initTable"
          >
            Show Deleted
          </md-switch>

          <md-switch
            v-model="filters.show_inactive"
            @change="initTable"
          >
            Show Inactive
          </md-switch>

          <md-switch
            v-model="filters.email_sent"
            :disabled="filters.show_inactive"
            @change="initTable"
          >
            Show Sent
          </md-switch>
        </div>
        <!-- BULK ACTION BUTTONS -->

        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getForms"
          @onShowDetails="onShowDetails"
          @onCopyLink="onCopyLink"
          @onShowStudentProfile="onShowStudentProfile"
          @onShowProgrammeDetails="onShowProgrammeDetails"
          @onApprove="onApprove"
          @onSendSignRequest="onSendSignRequest"
          @onSendEmail="sendEmail"
          @onDelete="onDelete"
          @onCheck="onCheck"
          @onShowLog="onShowLog"
          @onShowFlight="onShowFlight"
          @onShowPassport="onShowPassport"
          @onSetInactive="onSetInactive"
          @onSetActive="onSetActive"
        />
      </md-card-content>
    </md-card>
    <FormsModal
      v-if="showModal"
      ref="modal"
      :item="selectedForm"
      @close="onCloseModal"
      @onApprove="onApprove"
      @onCheck="onCheck"
      @onSave="$refs.vtable.init()"
    />
    <FormsLogModal
      v-if="logSelected"
      :item="logSelected"
      @close="logSelected = null"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate, FormSelect } from '@/components/Inputs';
import {
  FormStatusesSelect,
  AgentsSelect,
} from '@/components/Inputs/selects';
import FormsModal from './FormsModal.vue';
import FormsLogModal from './FormsLogModal.vue';

export default {
  components: {
    VueTable,
    FormsModal,
    FormsLogModal,
    FormDate,
    FormStatusesSelect,
    AgentsSelect,
    FormSelect,
  },
  data: () => ({
    filters: {
      status: null,
      signed: null,
      email_sent: false,
      show_inactive: false,
      show_deleted: false,
      agent_id: null,
      email_sent_at: null,
    },
    vTable: {
      headers: [
        {
          title: 'student.student_number',
          mask: 'student number',
          sortable: false,
        },
        {
          title: 'email',
          sortable: true,
        },
        {
          title: 'signed',
          boolean: true,
          booleanValues: { true: 'YES', false: 'NO' },
        },
        {
          title: 'status',
          sortable: true,
        },
        {
          title: 'agency.name',
          mask: 'agency name',
        },
        {
          title: 'email_sent_at',
          mask: 'email sent',
          dateTimeFormat: true,
        },
        {
          title: 'agent.name',
          mask: 'agent',
        },
        {
          title: 'approved_at',
          mask: 'approved at',
          dateTimeFormat: true,
          sortable: true,
        },
        {
          title: 'checked_at',
          mask: 'checked at',
          dateTimeFormat: true,
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: 'md-success',
          tooltip: 'Check Data',
          callback: 'onCheck',
          icon: 'check',
        },
        {
          buttonClass: 'md-primary',
          tooltip: 'Re-Send SignRequest',
          callback: 'onSendSignRequest',
          icon: 'mail',
        },
        {
          buttonClass: 'md-info',
          tooltip: 'Go to Student Profile',
          callback: 'onShowStudentProfile',
          icon: 'person',
        },
        {
          buttonClass: 'md-info',
          tooltip: 'Go to Programme Details',
          callback: 'onShowProgrammeDetails',
          icon: 'shopping_bag',
        },
        {
          buttonClass: 'md-success',
          tooltip: 'Approve Form',
          callback: 'onApprove',
          icon: 'thumb_up_alt',
        },
        {
          buttonClass: 'md-info',
          tooltip: 'Show Details',
          callback: 'onShowDetails',
          icon: 'search',
        },
        {
          buttonClass: 'md-warning',
          tooltip: 'Copy Link to Student Form',
          callback: 'onCopyLink',
          icon: 'link',
        },
        {
          buttonClass: 'md-primary',
          tooltip: 'Send Email',
          callback: 'onSendEmail',
          icon: 'send',
        },
        {
          buttonClass: 'md-danger',
          tooltip: 'Deactivate Form',
          callback: 'onSetInactive',
          icon: 'visibility_off',
        },
        {
          buttonClass: 'md-success',
          tooltip: 'Re-activate Form',
          callback: 'onSetActive',
          icon: 'visibility',
        },
        {
          buttonClass: 'md-danger',
          tooltip: 'Delete',
          callback: 'onDelete',
          icon: 'close',
        },
        {
          buttonClass: 'md-info',
          tooltip: 'Show Log',
          callback: 'onShowLog',
          icon: 'info',
        },
      ],
      values: {},
    },
    showModal: false,
    selectedForm: null,
    logSelected: null,
    loading: false,
    agents: [],
    reasons: null,
  }),
  mounted() {
    this.initTable();
    this.getReasons();
  },
  methods: {
    initTable() {
      if (this.filters.show_deleted) {
        this.filters.email_sent = false;
        this.filters.show_inactive = false;
      }
      if (this.filters.show_inactive) { this.filters.email_sent = true; }

      this.$refs.vtable.init();
    },
    getReasons() {
      this.request('student_forms/reasons', 'get', {}, (reasonsRes) => {
        this.reasons = reasonsRes.data.reduce((a, b) => {
          a[b.student_form_delete_reason_id] = b.title;
          return a;
        }, {});
      });
    },
    getForms(params = {}) {
      this.loading = true;
      this.request('student_forms', 'get', {
        paginate: true,
        ...this.filters,
        ...params,
      }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onApprove = x.status === 'Fully Answered';
          x.onCheck = x.status === 'Fully Answered' && !x.checked_at;
          x.onShowStudentProfile = x.student_id !== null;
          x.onShowProgrammeDetails = x.programme_id !== null;
          x.onDelete = !x.email_sent && x.status !== 'Approved' && !this.filters.show_deleted;
          x.onSetInactive = x.email_sent && x.status !== 'Approved' && !x.is_inactive;
          x.onSetActive = x.email_sent && x.status !== 'Approved' && x.is_inactive;
          x.onSendSignRequest = !x.details.signed
            && !!x.details.sign_request_uuid
            && this.signRequestActive;
          x.onSendEmail = !['Approved'].includes(x.status) && !x.email_sent;
          return x;
        });
      }, () => {
        this.loading = false;
      });
    },
    createNewStudent() {
      this.showModal = true;
    },
    onCloseModal() {
      this.showModal = false;
      this.selectedForm = null;
    },
    onShowDetails(item) {
      this.showModal = true;
      this.selectedForm = item;
    },
    onCopyLink(item) {
      this.$clipboard(window.location.origin + (this.$router.resolve({ name: 'StudentForm', params: { token: item.token } })).href);
      this.fireSuccess('Link Copied to Clipboard succesfully!');
    },
    onShowStudentProfile(item) {
      this.$router.push({ name: 'StudentResume', params: { id: item.student_id } });
    },
    onShowProgrammeDetails(item) {
      this.$router.push({ name: 'ProgrammeDetails', params: { id: item.programme_id } });
    },
    onApprove(item) {
      this.loading = true;
      this.request(`student_forms/${item.student_form_id}/approve`, 'put', null, () => {
        // reset table
        this.$refs.vtable.init();
        this.$refs.modal.close();
        this.fireSuccess('Student created');
      }, () => {
        this.loading = false;
      });
    },
    onSendSignRequest(item) {
      this.fireConfirm(
        'Re-Sending SignRequest',
        'Are you sure you want to re-send a SignRequest for this Application Form?',
      ).then(() => {
        this.request(`student_forms/${item.student_form_id}/resend_sign_request`,
          'post',
          {},
          () => {
            this.fireSuccess('Email sent succesfully!');
          });
      });
    },
    onDelete(item) {
      this.fireModal({
        title: 'Destroying Application Form',
        html: 'Are you sure you want to delete all the data for this Application Form?',
        input: 'select',
        inputPlaceholder: 'Select a Reason',
        inputOptions: this.reasons,
      }).then((data) => {
        if (data.value) {
          this.request(`student_forms/${item.student_form_id}`,
            'delete',
            { reason_id: data.value },
            () => {
              this.fireSuccess('Application Form deleted succesfully!');
              this.initTable();
            });
        }
      });
    },
    onCheck(item) {
      this.fireConfirm(
        'Checking Application Form Data',
        'Are you sure all the data for this Application Form is correct?',
      ).then(() => {
        this.request(`student_forms/${item.student_form_id}/check`,
          'put',
          {},
          () => {
            this.fireSuccess('Application Form checked succesfully!');
            this.initTable();

            if (this.$refs.modal) {
              this.$refs.modal.close();
            }
          });
      });
    },
    onShowLog(item) {
      this.logSelected = item;
    },
    sendEmail(item) {
      this.fireConfirm('You are about to send an email', 'Are you sure?').then(() => {
        this.loading = true;
        this.request('student_forms/send_email', 'post', { student_form_id: item.student_form_id }, () => {
          this.initTable();
        }, () => {
          this.loading = false;
        });
      });
    },
    sendEmailBulk() {
      this.fireConfirm('You are about to send several emails', 'Are you sure?').then(() => {
        this.loading = true;
        this.request('student_forms/send_email', 'post', null, () => {
          this.initTable();
        }, () => {
          this.loading = false;
        });
      });
    },
    onSetInactive(item) {
      this.fireConfirm(
        'Deactivating Application Form',
        'Are you sure you want to do this?',
      ).then(() => {
        this.loading = true;
        this.request(`student_forms/${item.student_form_id}/deactivate`, 'put', null, (data) => {
          this.fireSuccess(data);
          this.initTable();
        }, () => {
          this.loading = false;
        });
      });
    },
    onSetActive(item) {
      this.fireConfirm(
        'Re-activating Application Form',
        'Are you sure you want to do this?',
      ).then(() => {
        this.loading = true;
        this.request(`student_forms/${item.student_form_id}/activate`, 'put', null, (data) => {
          this.fireSuccess(data);
          this.initTable();
        }, () => {
          this.loading = false;
        });
      });
    },
  },
};
</script>

<style>

</style>
